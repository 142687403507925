import React from "react";
import { LocalizationStore } from "./src/context/LocalizationContext";
import { NotificationStore } from "./src/context/NotificationContext";
import { FaultNotificationStore } from "./src/context/FaultNotificationContext";
import { PageTypeStore } from "./src/context/PageTypeContext";
import { SearchStore } from "./src/context/SearchContext";
import { UserStore } from "./src/context/UserContext";
import { PathStore } from "./src/context/PathContext";
import localizationFi from "./src/localization/fi.json";
import localizationSV from "./src/localization/sv.json";
import localizationEN from "./src/localization/en.json";

export const wrapRootElement = ({ element }) => (
  <LocalizationStore>
    <NotificationStore>
      <FaultNotificationStore>
        <PageTypeStore>
          <SearchStore>
            <UserStore>
              <PathStore>{element}</PathStore>
            </UserStore>
          </SearchStore>
        </PageTypeStore>
      </FaultNotificationStore>
    </NotificationStore>
  </LocalizationStore>
);

export const onRouteUpdate = () => {
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    const dataLayer = window.dataLayer;
    dataLayer.push({ event: `gatsby-route-change` });
  }, 50);
};

export const onClientEntry = () => {
  if (window && window.document && window.document.documentMode) {
    const ieCheck = sessionStorage.getItem("ieCheck");

    if (!ieCheck) {
      const txtFI = localizationFi.browser.internetExplorerAlert;
      const txtSV = localizationSV.browser.internetExplorerAlert;
      const txtEN = localizationEN.browser.internetExplorerAlert;

      let txtAlert = txtFI + "\n\n" + txtSV + "\n\n" + txtEN;
      alert(txtAlert);
      sessionStorage.setItem("ieCheck", 1);
    }
  }
};
