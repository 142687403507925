import React, { useState } from 'react';

export const FaultNotificationContext = React.createContext({
  faultNotifications: [],
  setFaultNotifications: () => [],
  fetchDateTime: undefined,
  setFetchDateTime: () => {}
});

export const FaultNotificationStore = ({ children }) => {
  const [faultNotifications, setFaultNotifications] = useState();
  const [fetchDateTime, setFetchDateTime] = useState();
  return (
    <FaultNotificationContext.Provider 
        value={{ 
          faultNotifications,
          setFaultNotifications,
          fetchDateTime,
          setFetchDateTime
        }}
    >
        {children}
    </FaultNotificationContext.Provider>
  );
};
