export default {
  header: {
    color: "text",
    textDecoration: "none",
    ":hover": { opacity: 0.8 },
  },
  footer: {
    display: "block",
    color: "#fff",
    textDecoration: "none",
    ":hover": { opacity: 0.8 },
  },
};
