const inputBase = {
  fontFamily: "body",
  fontSize: 2,
  bg: "lightGreen",
  border: "1px solid",
  borderColor: "primary",
  borderRadius: 0,
  color: "text",
  "::-webkit-input-placeholder": {
    color: "darkGrey",
  },
  "::-moz-placeholder": {
    color: "darkGrey",
  },
  ":-ms-input-placeholder": {
    color: "darkGrey",
  },
  "::placeholder": {
    color: "darkGrey",
  },
  ":focus": {
    boxShadow: "none",
    outline: "none",
    bg: "white",
  },
};

export default {
  label: {
    fontWeight: "bold",
    fontSize: 0,
    mb: 1,
  },

  labels: {
    large: {
      fontSize: 2,
    },
    error: {
      fontSize: 0,
      color: "error",
    },
  },

  input: {
    ...inputBase,
    lineHeight: 1,
  },
  select: {
    ...inputBase,
  },
  checkbox: {
    mr: 1,
    color: "primary",
    bg: "lightGreen",
  },
  radio: {
    mr: 1,
    color: "primary",
    bg: "lightGreen",
  },
  textarea: {
    ...inputBase,
  },
  slider: {},
};
