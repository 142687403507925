// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-danskebank-admin-js": () => import("./../../../src/pages/danskebank/admin.js" /* webpackChunkName: "component---src-pages-danskebank-admin-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-articles-js": () => import("./../../../src/templates/articles.js" /* webpackChunkName: "component---src-templates-articles-js" */),
  "component---src-templates-content-hub-front-page-js": () => import("./../../../src/templates/contentHubFrontPage.js" /* webpackChunkName: "component---src-templates-content-hub-front-page-js" */),
  "component---src-templates-content-hub-page-js": () => import("./../../../src/templates/contentHubPage.js" /* webpackChunkName: "component---src-templates-content-hub-page-js" */),
  "component---src-templates-content-hub-tag-page-js": () => import("./../../../src/templates/contentHubTagPage.js" /* webpackChunkName: "component---src-templates-content-hub-tag-page-js" */),
  "component---src-templates-content-hub-theme-page-js": () => import("./../../../src/templates/contentHubThemePage.js" /* webpackChunkName: "component---src-templates-content-hub-theme-page-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-content-topic-page-js": () => import("./../../../src/templates/contentTopicPage.js" /* webpackChunkName: "component---src-templates-content-topic-page-js" */),
  "component---src-templates-frontpage-js": () => import("./../../../src/templates/frontpage.js" /* webpackChunkName: "component---src-templates-frontpage-js" */),
  "component---src-templates-insurance-brochure-js": () => import("./../../../src/templates/insuranceBrochure.js" /* webpackChunkName: "component---src-templates-insurance-brochure-js" */),
  "component---src-templates-insurance-terms-js": () => import("./../../../src/templates/insuranceTerms.js" /* webpackChunkName: "component---src-templates-insurance-terms-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-section-js": () => import("./../../../src/templates/section.js" /* webpackChunkName: "component---src-templates-section-js" */),
  "component---src-templates-theme-js": () => import("./../../../src/templates/theme.js" /* webpackChunkName: "component---src-templates-theme-js" */)
}

