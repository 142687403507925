import React, { useCallback, useState } from "react";

export const PathContext = React.createContext({
  visitedPaths: [],
  setVisitedPaths: () => [],
});

export const PathStore = ({ children }) => {
  const [visitedPaths, setVisitedPaths] = useState([]);
  const addPath = useCallback(
    path => {
      setVisitedPaths(prevState => {
        const newState = [...prevState];
        newState.push(path);
        return newState;
      });
    },
    [setVisitedPaths]
  );
  return (
    <PathContext.Provider
      value={{
        visitedPaths,
        addPath,
      }}
    >
      {children}
    </PathContext.Provider>
  );
};
