import colors from "./colors";
import styles from "./styles";
import buttons from "./buttons";
import links from "./links";
import forms from "./forms";

const theme = {
  config: {
    useCustomProperties: false,
    useRootStyles: false,
  },
  colors,
  breakpoints: ["48em", "71em", "80em"],
  space: [0, 8, 16, 24, 32, 48, 64, 80],
  fonts: {
    body: '"FenniaSans", system-ui, sans-serif',
    heading: '"FenniaSans", system-ui, sans-serif',
  },
  fontSizes: [
    ".875rem", // 14px
    "1rem", // 16px
    "1.167rem", // 18.672px for accessible links
    "1.25rem", // 20px
    "1.5rem", // 24px
    "2rem", // 32px
    "2.5rem", // 40px
  ],
  fontWeights: {
    body: "normal",
    heading: "bold",
    bold: "bold",
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  text: {
    heading: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
    },
    body: {
      fontFamily: "body",
      fontWeight: "body",
    },
    bold: {
      fontWeight: "bold",
    },
  },
  sizes: {
    container: 1400,
  },
  layout: {
    container: {
      maxWidth: 1400,
      px: [3, 4],
    },
    narrow: {
      maxWidth: 960,
      px: [3, 4],
    },
    footer: {
      bg: "darkGrey",
      color: "white",
    },
    lead: {
      maxWidth: 1194,
      mx: "auto",
      px: [0, null, 4],
      fontSize: [2, null, 3],
      textAlign: "center",
    },
  },
  shadows: {
    dropdown: `0 0.9px 2.2px rgba(0, 0, 0, 0.02),
      0 2.1px 5.3px rgba(0, 0, 0, 0.028),
      0 4px 10px rgba(0, 0, 0, 0.035),
      0 7.1px 17.9px rgba(0, 0, 0, 0.042),
      0 13.4px 33.4px rgba(0, 0, 0, 0.05),
      0 32px 80px rgba(0, 0, 0, 0.07)`,
  },
  links,
  styles,
  buttons,
  forms,
};

export default theme;
