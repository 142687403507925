export default {
  primary: "#008A28",
  lightGrey: "#CCCCCC",
  grey: "#777777",
  darkGrey: "#333333",
  black: "#000000",
  text: "#333333",
  background: "#fff",
  muted: "#F5F5F5",
  highlight: "#EBEBEB",
  hover: "#00A830",
  secondary: "#18572A",
  lightGreen: "#F2F8F2",
  darkGreen: "#004C07",
  blue: "#008299",
  success: "#008A28",
  warning: "#AA6D03",
  error: "#D10000",
  informational: "#008299",
  notice: "#EBF06E",
  faultNotificationBackground: "#EBF06E",
};
