import React, { useState, createContext, useCallback } from 'react';

export const SEVERITY = {
  INFO: 0,
  WARN: 1,
  ERROR: 2,
};

export const NotificationContext = createContext({
  notification: {},
  showNotification: () => {},
  hideNotification: () => {},
});

export const NotificationStore = ({ children }) => {
  const [notification, setNotification] = useState();  
  const showNotification = useCallback((message, severity = SEVERITY.INFO) => {    
    const date = +new Date();    
    setNotification({msg: message, severity, date });
  }, [setNotification]);
  const hideNotification = useCallback(() => {
    setNotification(null);
  }, [setNotification]);  
  return (
    <NotificationContext.Provider 
        value={{ 
          notification,
          showNotification,
          hideNotification }}
    >
        {children}
    </NotificationContext.Provider>
  );
};
