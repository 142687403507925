import React, { useState } from 'react';

export const UserContext = React.createContext({
  username: null,
  setUsername: () => {},
});

export const UserStore = ({ children }) => {
  const [username, setUsername] = useState();  
  return (
    <UserContext.Provider 
        value={{ 
          username,
          setUsername
        }}
    >
        {children}
    </UserContext.Provider>
  );
};
