import React, { useState } from "react";

export const PageTypeContext = React.createContext({
  pageType: undefined,
  setPageType: () => {},
});

export const PageTypeStore = ({ children }) => {
  const [pageType, setPageType] = useState();
  return (
    <PageTypeContext.Provider
      value={{
        pageType,
        setPageType,
      }}
    >
      {children}
    </PageTypeContext.Provider>
  );
};
