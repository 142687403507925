import React, { useState, createContext } from 'react';
import localizationFi from '../localization/fi.json';
import localizationSv from '../localization/sv.json';
import localizationEn from '../localization/en.json';

const defaultLanguage = 'fi';

const translations = {
  fi: localizationFi,
  sv: localizationSv,
  en: localizationEn
};

export const LocalizationContext = createContext({
  language: 'fi',
  setLanguage: () => {},
  translate: () => '',
  getLocale: () => ''
});

export const LocalizationStore = ({ children }) => {
  const [language, setLanguage] = useState('fi');  
  const translate = (str, params, lang = language) => {
    if (!str) return '';
    const langTranslations = translations[lang] || translations[defaultLanguage];
    let val = str.split('.').reduce((prev, curr) => prev && prev[curr], langTranslations);
    if (!val) return `No translations for ${str} in ${lang}`;
    for (let param in params) {
      val = val.split('${'+param+'}').join(params[param]);
    }
    return val;
  };
  const getLocale = () => {
    if (language === 'sv') {
      return 'sv-SE';
    } else if (language === 'en') {
      return 'en-US';
    }
    return 'fi-FI';
  };
  return (
    <LocalizationContext.Provider 
        value={{ 
            language,
            setLanguage,
            translate,
            getLocale }}
    >
        {children}
    </LocalizationContext.Provider>
  );
};
