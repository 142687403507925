export default {
  root: {
    fontFamily: "body",
    lineHeight: "body",
    fontWeight: "body",
    fontSize: 2,
    fontVariantLigatures: "none",
    textRendering: "optimizeLegibility",
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    "@media print": {
      fontSize: 0,
      WebkitPrintColorAdjust: "exact !important",
      colorAdjust: "exact !important",
    },
  },
  h1: {
    variant: "text.heading",
    fontSize: [5, null, 6],
  },
  h2: {
    variant: "text.heading",
    fontSize: [4, null, 5],
  },
  h3: {
    variant: "text.heading",
    fontSize: [3, null, 4],
  },
  h4: {
    variant: "text.heading",
    fontSize: [2, null, 3],
  },
  h5: {
    variant: "text.heading",
    fontSize: 2,
  },
  h6: {
    variant: "text.heading",
    fontSize: 1,
  },
  p: {
    variant: "text.body",
  },
  a: {
    color: "primary",
    textDecoration: "none",
    "&:hover": {
      color: "hover",
    },
  },
  ol: {
    fontFamily: "body",
  },
  ul: {
    fontFamily: "body",
    paddingInlineStart: "1.5em",
    "> li:last-of-type": {
      mb: 0,
    },
  },
  li: {
    fontFamily: "body",
    mb: 1,
  },
  strong: {
    variant: "text.bold",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  th: {
    py: 1,
    px: 2,
    border: 0,
    borderBottomStyle: "solid",
    borderBottomColor: "grey",
    borderBottomWidth: "2px",
    fontWeight: "normal",
  },
  tr: {
    ":nth-child(even)": {
      bg: "lightGreen",
    },
  },
  td: {
    textAlign: "left",
    border: 0,
    py: 2,
    px: 1,
  },
  img: {
    maxWidth: "100%",
  },
  blockquote: {
    borderLeftStye: "solid",
  },
};
