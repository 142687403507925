const buttonBase = {
  display: "inline-block",
  py: 12,
  px: 3,
  fontFamily: "body",
  fontWeight: "bold",
  fontSize: 2,
  lineHeight: 1.25,
  textDecoration: "none",
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "transparent",
  borderRadius: 0,
  cursor: "pointer",
  bg: "unset",
};

export default {
  primary: {
    ...buttonBase,
    color: "white",
    bg: "primary",
    ":hover": {
      color: "white",
      bg: "hover",
    },
    ":active": {
      color: "white",
      bg: "secondary",
    },
    ":disabled, :disabled:hover": {
      color: "white",
      bg: "lightGrey",
    },
  },
  secondary: {
    ...buttonBase,
    color: "primary",
    bg: "transparent",
    borderColor: "currentColor",
    ":hover": { color: "hover" },
    ":active": {
      color: "secondary",
    },
    ":disabled": {
      color: "lightGrey",
    },
  },
  secondaryDark: {
    ...buttonBase,
    color: "darkGreen",
    borderColor: "currentColor",
    ":hover": { color: "primary" },
    ":active": {
      color: "secondary",
    },
    ":disabled": {
      opacity: 0.32,
    },
  },
  light: {
    ...buttonBase,
    color: "white",
    bg: "transparent",
    borderColor: "currentColor",
    ":hover": { color: "white", opacity: 0.8 },
    ":active": {
      opacity: 0.48,
    },
    ":disabled": {
      opacity: 0.32,
    },
  },
  plain: {
    ...buttonBase,
    color: "primary",
    ":hover": { color: "hover" },
    ":active": {
      color: "secondary",
    },
    ":disabled": {
      color: "lightGrey",
    },
    border: 0,
    p: 0,
  },
  arrow: {
    variant: "buttons.plain",
    "::after": {
      display: "inline-block",
      content: "'→'",
      ml: 1,
    },
  },
  pager: {
    ...buttonBase,
    color: "black",
    bg: "white",
    ":hover": {
      color: "white",
      opacity: 0.8,
    },
  },
  pagerSelected: {
    ...buttonBase,
    color: "black",
    bg: "muted",
    ":hover": {
      color: "white",
      opacity: 0.8,
    },
  },
};
